import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import { ReactComponent as TransactionIcon } from "../icons/receipt.svg";
import LoadingModal from "../components/LoadingModal";

const KioskReports = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    getPadala();

  },[]);

  const getPadala = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('kiosk_sales_report',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'ID', selector: data=>data.sequence, sortable: true },
        { name: 'Voucher Number', selector: data=>data.voucher_number, sortable: true },
        { name: 'PDL Name', selector: data=>data.pdl_name, sortable: true },
        { name: 'Service', selector: data=>data.service, sortable: true },
        { name: 'Transaction Amount', selector: data=>data.total_price.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), sortable: true, right: true,
          headerStyle: {
            textAlign: 'center',
          }, },
        { name: 'Transaction Date', selector: data=>data.reg_date, sortable: true },
        { name: 'Merchant Name', selector: data=>data.merchant_name, sortable: true }
    ]

    setColumns(_col);
    setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }

  return (
    <div>
      <LoadingModal show={loading} message={"Loading Kiosk Sales Report..."}/>
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
         Kiosk Sales Report
      </div>
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
      >
        
      </DataTable>
    </div>
  );
};

export default KioskReports;
