import DataTable from "react-data-table-component";
import SearchBar from "../SearchBar";
import React from "react";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import { adminServices } from "../../services/AdminServices";
import {ReactComponent as ItemIcon} from "../../icons/item.svg";

const ItemsComponent = () => {
    const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState("items");

  useEffect(()=>{
    getInventory();
  },[])

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filteredRows = data.filter((row) =>
      row.sku.toLowerCase().includes(query) ||
      row.product_name.toLowerCase().includes(query) ||
      row.primary_supplier.toLowerCase().includes(query)
    );
    setFilteredData(filteredRows);
  };

  const conditionalRowStyles = [
    {
        when: row => row.current_quantity < row.critical_quantity,
        style: {
            backgroundColor: 'red',
            color: 'white',
        },
    },
];

  const getInventory = async()=>{
      var form = new FormData();
      form.append('params','');
      await adminServices.storedProcedure('warehouse_inventory',form).then((r)=>{
          setData(r.data);
          setFilteredData(r.data);
          var _col = [
            { name: 'ID', selector: data=>data.id, sortable: true },
            { name: 'Product Name', selector: data=>data.product_name, sortable: true },
            { name: 'Sku', selector: data=>data.sku, sortable: true },
            { name: 'Details', selector: data=>data.details , sortable: true },
            { name: 'Primary Supplier', selector: data=>data.primary_supplier, sortable: true },
            { name: 'Qty', selector: data=>data.current_quantity, sortable: true },
            { name: 'Critical QTY', selector: data=>data.critical_quantity, sortable: true },
          ]
          setColumns(_col);
      }).catch((e)=>{
        if(e.response.status == 401){
          window.location.href= "/";
        }
      });
  }

    return (<div>
        <div className="flexRow">
            <ItemIcon className="inv-icon crumb"/>
            <h2>Items</h2>
        </div>
        <div className="flexRow filterDiv">
            <SearchBar
            placeholder="Search product"
            onChange={handleSearchChange}
            />
        </div>

      <div className="spacer-v"></div>

      <div className="tableContainer">
        <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            onRowClicked={()=>{}}
            conditionalRowStyles={conditionalRowStyles}
          />
      </div>
    </div>)
}

export default ItemsComponent;