import React,{ useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {ReactComponent as VoidIcon} from '../icons/void.svg';
import LoadingModal from "../components/LoadingModal";
import { adminServices } from "../services/AdminServices";

const VoidTransactions = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getVoidTransactions();
    },[]);

    const getVoidTransactions = async ()=>{
        var form  = new FormData();
        form.append("params", "");
        await adminServices.storedProcedure('void_transactions',form).then((r)=>{
            setLoading(false);
            setData(r.data);
            let _col = [
                { name: 'Voucher/Reference No', selector: data=>data.ref_no, sortable: true },
                { name: 'PDL No', selector: data=>data.pdl_number, sortable: true },
                { name: 'PDL Name', selector: data=>data.pdl_name, sortable: true },
                { name: 'Transaction', selector: data=>data.transaction_type, sortable: true },
                { name: 'Transaction Date', selector: data=>data.transaction_date, sortable: true },
                { name: 'Voided Date', selector: data=>data.transaction_date, sortable: true },
                { name: 'Amount', selector: data=>data.amount.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }), sortable: true },
                { name: 'Void Reason', selector: data=>data.reason, sortable: true }
            ]
            setColumns(_col);
        }).catch((e)=>{
            console.log(e);
        });
    }

    return (
        <div>
            <LoadingModal show={loading} message={"Loading Void Transactions..."} />
            <div className="sc-fPXMhL QAQQD" style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
                <VoidIcon style={{ width: "50px", height: "50px", marginRight: "10px" }} />
                <h3>Void Transactions</h3>
            </div>
            <DataTable
                columns={columns}                
                data={data}
                pagination
                highlightOnHover
            />
        </div>);

};

export default VoidTransactions;