import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar";
import DataTable from "react-data-table-component";
import {ReactComponent as SupplierIcon} from "../../icons/supplier.svg";
import { adminServices } from "../../services/AdminServices";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../LoadingModal";

const SupplierComponent = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        initializeColumns();
        getSuppliers();

        return () => {
            console.log("Page initialized");
        }
    },[]);

    const initializeColumns = () => {
        const _columns = [
            { name: 'Supplier Name', selector: row => row.supplier_name, sortable: true, },
            { name: 'Contact Person', selector: row => row.contact_name, sortable: true, },
            { name: 'Email Address', selector: row => row.email, sortable: true, },
            { name: 'Phone Number', selector: row => row.contact_no, sortable: true, },
            { name: 'Fax No', selector: row => row.fax_no, sortable: true, },
            { name: 'Address', selector: row => `${row.address} ${row.city} ${row.region}`, sortable: true, },
            { name: 'Action', selector: row => <button className="action-button" onClick={()=>{ onEditClick(row) }}>Edit</button>, sortable: false, }
        ];

        setColumns(_columns);
    }

    const onEditClick = (e)=>{
        console.log(e);
    }

    const getSuppliers = async() => {
        let form = new FormData();
        form.append('params','');
        await adminServices.storedProcedure('get_supplier_list',form).then((res)=>{
            if(res.status === 200){
                setData(res.data);
                setLoading(false);
            }
        }).catch((error)=>{

            if(error.status === 401){
                navigate('/');
            }

        })
    }

    return (<div>
        <LoadingModal show={loading} message={"Loading suppliers..."} />
        <div className="flexRow">
            <SupplierIcon className="inv-icon crumb"/>
            <h2>Suppliers</h2>
        </div>
        <div className="flexRow filterDiv">
            <SearchBar
            placeholder="Search Suppliers"
            onChange={()=>{}}
            />
        </div>

        <div className="spacer-v"></div>
        <div className="tableContainer">
            <DataTable pagination={true} columns={columns} data={data} />
        </div>
    </div>)
}

export default SupplierComponent;