import React from "react";
import { ReactComponent as DashboardIcon } from "../icons/dashboard.svg";

const HomePage = () => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
        <DashboardIcon style={{ width: "50px", height: "50px", marginRight: "10px" }} />
        <h2>Admin Booth Dashboard</h2>
      </div>
      <p>Welcome to the dashboard!</p>
    </div>
  );
};

export default HomePage;
