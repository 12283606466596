import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import SearchBar from "../components/SearchBar";
import Modal from "react-modal";
import JSONPretty from "react-json-pretty";
import LoadingModal from "../components/LoadingModal";

const LogReports = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState('');
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [viewData, setViewData] = useState(false);
  const [isJson, setIsJson] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    getLogs();

  },[]);

  const getLogs = async ()=>{
    var form = new FormData();
    form.append('params','');
    try{
      const response = await adminServices.storedProcedure("get_logs", form);
      const logs = response.data;

      setKioskSales(null);
      setKioskSales(response.data);
      setData(response.data);
      setFilteredData(response.data);

      var _col = [
        { name: 'ID', selector: data=>data.audit_log_id, sortable: true, width: 50 },
        { name: 'Timestamp', selector: data=>data.date_time, sortable: true },
        { name: 'User', selector: data=>data.user, sortable: true },
        { name: 'Service', selector: data=>data.action, sortable: true },
        //{ name: 'Data', selector: data=>data.data, sortable: true },
        { name: 'Module', selector: data=>data.table, sortable: true },
    ]

      setColumns(_col);
      setLoading(false);
    }
    catch(e){

    }
  }

  const HTMLPrettyPrint = ({ html }) => (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );

  return (
    <div >
      <LoadingModal show={loading} message={"Loading Logs Report..."} />
      <h2>Logs Report</h2>
      <Modal isOpen={viewData} onRequestClose={()=>{ setViewData(false); setEventData(''); }}>
      <div style={{display:"flex",justifyContent:'end',position:'fixed',right:63,zIndex:999}}>
                    <button style={{backgroundColor:'#fff',
                        borderColor:'#fff',
                        color:'#555',
                        borderRadius:100,
                        fontSize:20,
                        fontWeight: 'bolder',
                        fontFamily: 'monospace',
                        borderWidth:0}}
                        onClick={()=>{ setViewData(false); setEventData(''); }}
                        >
                            x
                        </button>
                </div>
        <h2>Data Log</h2>
        <div>
          {isJson && <JSONPretty data={eventData} />}
          {!isJson && <HTMLPrettyPrint html={eventData} />}
        </div>
      </Modal>
      <div className="flexRow filterDiv">
        <SearchBar
          placeholder="Search"
          onChange={(event)=>{
            
            const query = event.target.value.toLowerCase();
            setSearchQuery(query);

            // Filter data based on search query
            const filteredRows = data.filter((row) =>
              //row.audit_log_id.includes(query) ||
              row.user.toLowerCase().includes(query) ||
              row.action.toLowerCase().includes(query) ||
              row.table.toLowerCase().includes(query)
            );
            setFilteredData(filteredRows);

          }}
        />
      </div>
      <div style={{ height: "25px" }}></div>
      <div style={{maxWidth:1330, overflow:'auto'}}>
        <DataTable 
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            style={{maxWidth:1330}}
            onRowClicked={(e)=>{
                setViewData(true);
                setEventData(e.data);
                try {
                  JSON.parse(e.data);
                  setIsJson(true);
                } catch (e) {
                  setIsJson(false);
                }
              
            }}
        >
          
        </DataTable>
      </div>
    </div>
  );
};

export default LogReports;
