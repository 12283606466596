import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { adminServices } from "../services/AdminServices";

const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '350px', margin: 'auto', 
        height : '350px'
      },
 };

const VoidModal = ({refNo, show, onClose,toVoid,voidEvent})=>{
    const [reason, setReason] = useState('');
    return (<div>
        <h2>Void Transaction {refNo}</h2>
        <Modal isOpen={show} onRequestClose={onClose} 
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
         >

            {/* Close Button */}
            <div style={{display:"flex",justifyContent:'space-between',zIndex:999}}>
                <h3>Void Transaction #<i>{refNo}</i></h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#fff',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <textarea onChange={(event)=>{setReason(event.target.value)}} style={{width:'90%',height:'50%',marginTop:10,marginLeft:10,marginRight:10,border:'none',padding:10,borderRadius:10,backgroundColor:'#f2f2f2'}}></textarea>
            <div style={{display:"flex",justifyContent:'end',marginTop:10,marginLeft:10,marginRight:10}}>
                <button style={{backgroundColor:'#b2b2b2', borderRadius:10, width:100, height:40, color:'#fff', fontWeight:'bold', fontFamily:'monospace', border:'none'}}
                    onClick={()=>{
                        var form = {
                            "refNo": refNo,
                            "reason" : reason
                        }
                        adminServices.postData('voidtransactions',form).then((response) => {
                            if(response.status === 200){
                                voidEvent()
                                onClose()
                            }
                        });
                    }}
                >
                    Void
                </button>
            </div>
        </Modal>
    </div>);
}

export default VoidModal;