import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { adminServices } from "../services/AdminServices";
import {ReactComponent as BackIcon} from "../icons/back.svg";
import LoadingModal from "../components/LoadingModal";

const MerchantTransactions = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [params, setParams] = useState(null);
  const paramsFromQuery = localStorage.getItem("selectedMerchant");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getQueryParams(atob(paramsFromQuery));
  }, []); 

  const handleTransactionClick = (id) => {
    navigate(`/transactions/${id}`);
  };

  const getQueryParams = async (str) => {
   setLoading(true);
    const json = JSON.parse(str);
    setParams(json);
    await getMerchantTransactions(json.id);
    
  }
  const getMerchantTransactions = async (id) => {
    var form = new FormData();
    form.append('params',id);
    adminServices.storedProcedure('get_merchant_transactions_by_id',form).then((response)=>{
        setTransactions(response.data);
        var _col = [
            { name: 'Transaction Date', selector: data=>data.timestamp, sortable: true },
            { name: 'Reference Number', selector: data=>data.ref_no, sortable: true },
            { name: 'PDL Number', selector: data=>data.pdl_number , sortable: true },
            { name: 'PDL', selector: data=>data.first_name + " " + data.last_name, sortable: true },
            { name: 'Amount', selector: data=>data && data.amount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }), sortable: true, right: true},
            { name: 'Remarks', selector: data=>data.remarks, sortable: true }
          ]
    
        setColumns(_col);
        setLoading(false);
    }).catch((error)=>{
        console.log(error);
        if(error.status === 401){
            navigate('/');
        }
    })
  }


  return (
    <div>
        <LoadingModal show={loading} message={"Loading Merchant Transactions..."} />
        <div style={{display: 'flex', justifyContent: 'start',alignItems: 'center', marginBottom: '20px'}}>
            <BackIcon style={{cursor: 'pointer', marginRight: '10px',width: '50px', height: '50px'}} onClick={()=>{
                navigate('/merchant');
            }}  />
            <h2>Merchant Transactions ({params && params.merchant_name} - Php {params && params.wallet_amount !== undefined ?  params.wallet_amount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : "0.00" }) </h2>
        </div>
        
        <DataTable 
            columns={columns}
            data={transactions}
            pagination
        />
     </div>);
};

export default MerchantTransactions;