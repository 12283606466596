import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar";
import DataTable from "react-data-table-component";
import {ReactComponent as MeasureIcon} from "../../icons/measure.svg";

const UnitsOfMeasuresComponent = () => {
    return (<div>
        <div className="flexRow">
            <MeasureIcon className="inv-icon crumb"/>
            <h2>Unit Of Measures</h2>
        </div>
        <div className="spacer-v"></div>
        <div className="tableContainer">
            <DataTable pagination={true} />
        </div>
    </div>)
}

export default UnitsOfMeasuresComponent;