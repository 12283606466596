import React, { useState, useEffect } from "react";
import {ReactComponent as PurchaseIcon} from "../../icons/purchase.svg";
import DataTable from "react-data-table-component";

const PurchaseRequestComponent = () => {
    return (<div>
        <div className="flexRow">
            <PurchaseIcon className="inv-icon crumb"/>
            <h2>Purchase Requests</h2>
        </div>
        <div className="spacer-v"></div>
        <div className="tableContainer">
            <DataTable 
                pagination
                highlightOnHover
            />
        </div>
    </div>);
}

export default PurchaseRequestComponent;