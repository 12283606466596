import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as DashboardIcon } from "../icons/dashboard.svg";
// import { ReactComponent as ProfileIcon } from "../icons/profile.svg";
import { ReactComponent as UserGroupIcon } from "../icons/user-group.svg";
import { ReactComponent as UsersIcon } from "../icons/users.svg";
import { ReactComponent as ReceiptIcon } from "../icons/receipt.svg";
import { ReactComponent as LoadIcon } from "../icons/load.svg";
import { ReactComponent as SupportIcon } from "../icons/support.svg";
import { ReactComponent as FlagIcon } from "../icons/flag.svg";
// import { ReactComponent as SettingsIcon } from "../icons/settings.svg";
import { ReactComponent as ShoppingBagIcon } from "../icons/shopping-bag.svg";
import { ReactComponent as ReceiptHistoryIcon } from "../icons/receipt-history.svg";
import { ReactComponent as MerchantIcon } from "../icons/merchant.svg";
import { ReactComponent as ColumnIcon } from "../icons/column.svg";
// import { ReactComponent as LogOutIcon } from "../icons/logout.svg";
import { ReactComponent as ReportIcon} from "../icons/report.svg";
import {ReactComponent as TransactionIcon} from "../icons/transactions.svg";
import {ReactComponent as InvertoryIcon} from "../icons/inventory.svg";
import {ReactComponent as KioskIcon} from   "../icons/kiosk.svg";
import {ReactComponent as POSIcon} from "../icons/pos.svg";
import {ReactComponent as VoidIcon} from "../icons/void.svg";
import { jwtDecode } from "jwt-decode";
const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const [role, setRole] = useState("");
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  useEffect(()=>{
      var token = localStorage.getItem("appToken");

      setRole(jwtDecode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
  },[]);



  return (
    <div className={`sidebar ${isActive ? "active" : ""}`}>
      
      <div className="logo-placeholder l t-offwhite">
        {/* <img
          src={require("../tmpImage/logoplaceholder.jpg")}
          style={{ width: "50px", borderRadius: "100px", flex: 1 }}
        /> */}
        {/* <Link to="/" className="link black">
          Admin Booth
        </Link> */}
      </div>
      <div className="line"></div>

      <Link to="/dashboard" className="sidebarItem">
        <DashboardIcon className="icon t-darkgray" />
        <p className="link r medium">Dashboard</p>
      </Link>
      <Link to="/pdl-management" className="sidebarItem">
        <UserGroupIcon className="icon t-darkgray" />
        <p className="link r medium">PDL Management</p>
      </Link>
      {role === "1" && <Link to="/padala-reports" className="sidebarItem">
        <TransactionIcon className="icon t-darkgray" />
        <p className="link r medium">Padala Reports</p>
      </Link>}
      {role == "3" && <Link to="/beucor-padala-reports" className="sidebarItem">
      <TransactionIcon className="icon t-darkgray" />
        <p className="link r medium">Bucor Padala Reports</p>
      </Link>}
      
      {/* <div className="line"></div> */}
      {role === "1" &&  <Link to="/kiosk-reports" className="sidebarItem">
        <ReceiptIcon className="icon t-darkgray" />
        <p className="link r medium">Kiosk Sales Report</p>
      </Link>}

      {role === "1" &&  <Link to="/void-transactions" className="sidebarItem">
        <VoidIcon className="icon t-darkgray" />
        <p className="link r medium">Void Transactions</p>
      </Link>}

      {role === "1" &&  <Link to="/floating-padala-reports" className="sidebarItem">
        <ReportIcon className="icon t-darkgray" />
        <p className="link r medium">Floating Padala Report</p>
      </Link>}

      

      {(role === "1" || role === "2") &&  <Link to="/load-money" className="sidebarItem">
        <LoadIcon className="icon t-darkgray" />
        <p className="link r medium">Load Money</p>
      </Link>}
     
      
      {/* <Link to="/load-merchant" className="sidebarItem">
        <LoadIcon className="icon t-darkgray" />
        <p className="link r medium">Load Money</p>
      </Link> */}
      {/* <div className="line"></div> */}
      {(role === "1" || role === "2") &&  <Link to="/inventory" className="sidebarItem">
        <InvertoryIcon  className="icon t-darkgray" />
        <p className="link r medium">Inventory</p>
      </Link>}

      {(role === "1" || role === "2") &&   <Link to="/merchant" className="sidebarItem">
        <MerchantIcon className="icon t-darkgray" />
        <p className="link r medium">Merchants</p>
      </Link>}
      
     
      {/* <NavLink
        to="/merchant"
        className="sidebarItem"
        activeClassName="sidebarItemActive"
      >
        <MerchantIcon className="icon t-darkgray" />
        <p className="link r medium">Merchants</p>
      </NavLink> */}
      <div className="spacer"></div>
      <div className="line"></div>
      {(role === "1" || role === "2") &&   <Link to="/kiosk-status-reports" className="sidebarItem">
        <KioskIcon className="icon t-darkgray" />
        <p className="link m medium">Kiosk Status Report</p>
      </Link>}

      {(role === "1" || role === "2") &&   <Link to="/pos-status-reports" className="sidebarItem">
        <POSIcon className="icon t-darkgray" />
        <p className="link m medium">POS Status Report</p>
      </Link>}
      
      
      <Link to="/support-help" className="sidebarItem">
        <SupportIcon className="icon t-darkgray" />
        <p className="link m medium">Support</p>
      </Link>
      {/* <Link to="http://ec2-13-212-191-239.ap-southeast-1.compute.amazonaws.com:4444/reports_pdls.php" className="sidebarItem">
        <FlagIcon className="icon t-darkgray" />
        <p className="link r medium">PDL Profiles and Credits</p>
      </Link> */}
      <div className="sidebarItem" onClick={handleToggle}>
        <ColumnIcon className="icon t-darkgray" />
        <div className="spacer link r medium">Collapse Sidebar</div>
      </div>

      {/* <div className="sidebarItem">
        <SettingsIcon className="icon t-darkgray" />
        <Link to="/system-settings" className="link r medium">
          System Settings
        </Link>
      </div> */}
      {/* <div className="sidebarItem">
        <ProfileIcon className="icon t-darkgray" />
        <Link to="/profile" className="link r medium">
          Profile
        </Link>
      </div>
      <div className="sidebarItem">
        <LogOutIcon className="icon t-darkgray" />
        <Link to="/profile" className="link r medium">
          Logout
        </Link>
      </div> */}
    </div>
  );
};

export default Sidebar;
