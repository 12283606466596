// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import LogInPage from "./pages/LogInPage";
import HomePage from "./pages/HomePage";
import PDLManagementPage from "./pages/PDLManagementPage";
import LoadMoneyPage from "./pages/LoadMoneyPage";
import InventoryPage from "./pages/InventoryPage";
import MerchantPage from "./pages/MerchantPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import MerchantPage_Edit from "./pages/MerchantPage_Edit";
import MerchantPage_Add from "./pages/MerchantPage_Add";
import LoadMerchantPage from "./pages/LoadMerchantPage";
import PadalaReports from "./pages/PadalaReports";
import KioskReports from "./pages/KioskReports";
import OtpPage from "./pages/OtpPage";
import PDLTransactions from "./pages/PDLTransactions";
import BeuPadalaReports from "./pages/BeuCorPadalaReports";
import PadalaReportsFloating from "./pages/PadalaReportsFloating";
import ReprocessPadala from "./pages/ReprocessPadala";
import RFQPage from "./pages/RFQPage";
import SettingsPage from "./pages/SettingsPage";
import LogReports from "./pages/LogReports";
import KioskStatusReports from "./pages/KioskStatusReports";
import RFQList from "./pages/RFQList";
import KioskPageAdd from "./pages/KioskPageAdd";
import POList from "./pages/POList";
import POPage from "./pages/POPage";
import ReceivingPage from "./pages/ReceivingPage";
import MPOPage from "./pages/MPOPage";
import POSStatusReports from "./pages/POSStatusReports";
import FundTransferPage from "./pages/FundTransferPage";
import MerchantTransactions from "./pages/MerchantTransactions";
import NotFound from "./pages/NotFound";
import VoidTransactions from "./pages/VoidTransactions";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LogInPage />} />
        <Route path="/verify-otp" element={<OtpPage />} />
        <Route
          path="*"
          element={
            <AppLayout>
              <Routes>
                <Route path="/" element={<NotFound />} />
                <Route path="/dashboard" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/load-money" element={<LoadMoneyPage />} />
                <Route path="/fund-transfer" element={<FundTransferPage />} />
                <Route path="/load-merchant" element={<LoadMerchantPage />} />
                <Route path="/inventory" element={<InventoryPage />} />
                <Route path="/merchant" element={<MerchantPage />} />
                <Route path="/merchant/edit" element={<MerchantPage_Edit />} />
                <Route path="/merchant/add" element={<MerchantPage_Add />} />
                <Route path="/pdl-management" element={<PDLManagementPage />} />
                <Route path="/padala-reports" element={<PadalaReports />} />
                <Route path="/beucor-padala-reports" element={<BeuPadalaReports />} />
                <Route path="/floating-padala-reports" element={<PadalaReportsFloating />} />
                <Route path="/kiosk-reports" element={<KioskReports />} />
                <Route path="/void-transactions" element={<VoidTransactions />} />
                <Route path="/kiosk-add" element={<KioskPageAdd />} />
                <Route path="/pdl-transactions" element={<PDLTransactions />} />
                <Route path="/reprocess" element={<ReprocessPadala />} />
                <Route path="/rfq" element={<RFQPage />} />
                <Route path="/rfq-list" element={<RFQList />} />
                <Route path="/po-list" element={<POList />} />
                <Route path="/receiving" element={<ReceivingPage />} />
                <Route path="/po" element={<POPage />} />
                <Route path="/mpo" element={<MPOPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/logs" element={<LogReports />} />
                <Route path="/kiosk-status-reports" element={<KioskStatusReports />} />
                <Route path="/pos-status-reports" element={<POSStatusReports />} />
                <Route path="/merchant-transactions" element={<MerchantTransactions />} />
              </Routes>
            </AppLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
